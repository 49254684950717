@import './app/assets/stylesheets/color_variables.scss';
.static-disabled-input {
  font-family: 'MaisonNeueLight';
  width: 100%;
  padding: 4px;
  color: $palette_neutral88;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: $palette_neutral12;
  z-index: 1;
}

.disabled-container {
  margin-left: 40px;
  margin-top: -25px;

  & > label {
    font-family: 'MaisonNeueMedium';
    color: $palette_neutral65;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 20px;
  }
}
