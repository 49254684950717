$datepicker__background-color: #f0f0f0 !default;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: #216ba5 !default;
$datepicker__text-color: #000 !default;
$datepicker__outside-month-color: #737070 !default;

$datepicker__border-radius: .3em !default;
$datepicker__day-margin: .166em !default;
$datepicker__font-size: .8em !default;
$datepicker__item-size: 1.7em !default;
$datepicker__margin: .4em !default;
$datepicker__navigation-size: .45em !default;
$datepicker__triangle-size: 8px !default;
