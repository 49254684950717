$light_gray: #eae8e6;
$white: #ffffff;
$black: #000;
$red_error: #ff3a3a;
$light_grey: #d8d8d8;

// V2 COLORS

$v2_light_purple: #fcfcff;
$v2_rhino_purple: #6121c8;
$v2_slate: #12304f;
$v3_slate_3: #f0f3f5;
$v2_jean: #3939b8;
$rhino_green: #0baa3f;
$terms_of_use: #737373;

//PALETTE COLORS

$palette_neutral4: #f5f5f5;
$palette_neutral12: #e0e0e0;
$palette_neutral25: #bfbfbf;
$palette_neutral55: #737373;
$palette_neutral88: #1f1f1f;
$palette_neutral65: #595959;
$palette_brand100: #6b3bf6;
$palette_brand125: #502cb9;
$palette_brand4: #f9f7fe;
$palette_interaction100: #315bf1;
$palette_neutral_dark: #000000;

// BLOG COLORS

$blog_green: #32cc65;
$blog_blue: #3380d9;
$blog_purple: #6b3bf6;
