.error-message {
  padding-bottom: 15px;

  &__text {
    color: #ff3a3a;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: .8px;
  }
}
.sign-up__form {
  .error-message {
    background-color: transparent;
  } 
}
