$border-color: #999;

$yes-background: #2A2E76;
$yes-text: #FFF;

$no-background: #4EC9D4;
$no-text: #000;

$switch-border: #999;
$switch-color: #FFF;

.onoffswitch {
  position: relative; width: 80px;
  -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;

  border: 2px solid $border-color;
  border-radius: 30px;
}
.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;

  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;

  font-size: 14px;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;

  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: "YES";
  padding-left: 10px;

  background-color: $yes-background;
  color: $yes-text;
}
.onoffswitch-inner:after {
  content: "NO";
  padding-right: 10px;

  background-color: $no-background;
  color: $no-text;

  text-align: right;
}
.onoffswitch-switch {
  display: block;
  width: 33px;
  margin: 0.5px;

  background: $switch-color;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 46px;

  border: 2px solid $switch-border; border-radius: 30px;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
